import { RootState } from '@store';
import path from 'path';

export namespace APIRoutes {
  export enum Config {
    get = '/v1/config',
  }
  export enum Finishes {
    getAll = '/v2/surface-prep/finishes',
  }
  export enum Materials {
    getAll = '/v1/surface-prep/materials',
  }
  export enum Machines {
    getAll = '/v1/surface-prep/machine-models',
    getToolSystem = '/v2/surface-prep/tool-systems',
  }
  export enum Recipes {
    query = '/v1/surface-prep/recipes/query',
    export = '/v1/surface-prep/export',
  }
  export enum Tools {
    getFamilies = '/v1/surface-prep/tool-families',
    getFamily = '/v1/surface-prep/tool-families/:toolFamilyId',
    get = '/v1/surface-prep/dimrTool',
  }
  export enum Session {
    anonymous = '/v1/session/anonymous',
    country = '/v1/users/current',
  }
  export enum Configurations {
    valid = '/v1/surface-prep/configurations/valid',
  }
}

export interface RejectValue {
  rejectValue: any,
  state: RootState
}
export interface APIEnabledSlice {
  isFetching: boolean,
}

export const makeRequest = async (endpoint: string, options: Partial<RequestInit>) => {

  const headers = new Headers(options.headers);

  if (options.method === 'POST' || options.method === 'PATCH') {
    headers.append('Accept', 'application/json');
  }

  if (!headers.has('Content-Type')) {
    headers.append('Content-Type', 'application/json');
  }

  const fetchOptions = {
    ...options,
    headers,
  };

  const response = await fetch(endpoint, fetchOptions);

  if (response.status === 204) {
    return Promise.resolve();
  }

  if (!response.ok) {
    const error = await response.json();
    return Promise.reject(error);
  }

  if (headers.get('Content-Type') === 'application/json') {
    return response.json();
  }
  return response.text();
};

export const toolSelectorAPI = async (
  thunkAPI: any,
  pathname: string,
  options: RequestInit) => {
  const { getState, rejectWithValue } = thunkAPI;
  const { config, session } = getState();
  const backendURL = config?.item?.endpoint || env?.api?.host;

  if (!pathname) {
    throw new Error('Please specify pathname');
  }

  const headers = new Headers(options.headers);
  if (config?.item) {
    headers.append('X-Api-Key', config?.item?.apiKey);
  }
  if (session?.sessionId && (session?.isCountrySet || pathname === APIRoutes.Session.country)) {
    headers.append('X-Session', session?.sessionId);
  }

  try {
    const [protocol, url] = backendURL.split(/(https?:\/\/)/)?.slice(1, 3);
    return await makeRequest(`${protocol}${path.join(url, pathname)}`, { ...options, headers });
  } catch (err) {
    const error = err;
    if (!options) {
      return rejectWithValue(error);
    }

    return rejectWithValue({ supressedError: error });
  }
};

export const withQuery = (route: string, query: Object = {}) => `${route}?${Object.entries(query).filter(a => a.every(e => !!e)).map(a => a.join('=')).join('&')}`;

export const withParams = (route: string, params: Object) => Object.entries(params).reduce((acc, pp) => acc.replace(`:${pp[0]}`, pp[1]), route);
