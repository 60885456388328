import { useEffect } from 'react';
import { RecipeStep } from '@store/recipesSlice';
import useTool from '@hooks/useTool';
import useSelection from '@hooks/useSelection';
import useRecipes from '@hooks/useRecipes';
import Button from '@components/common/Button/Button';
import { calculateQuantity, extractGallons, extractLiters, formatProductNumber, marketFormats } from '@utils/formatting';
import './RecipeItem.scss';

interface RecipeItemProps extends RecipeStep {
  stepNumber: number;
  machineModelId: string | undefined;
  onEdit: (index: number) => void;
}

const toSimpleDataRow = (key: string, value: string) => (
  <div key={`${key}${value}`} className="row space">
    <span>{key}</span>
    <span>{value}</span>
  </div>
);

const RecipeItem = (props: RecipeItemProps) => {
  const { stepNumber, machineModelId, type, tool, onEdit } = props;
  const { market } = useRecipes();
  const { tool: dimrTool, getTool } = useTool(tool.dimrToolId);
  const { site } = useSelection();

  const { units, convertArea } = marketFormats[market];

  useEffect(() => {
    if (tool?.dimrToolId) {
      getTool();
    }
  }, [tool]);

  const toTopDataRow = (key: string, value?: string) => (
    <div key={`${key}${value}`} className="row space">
      <span className="bold">{key}</span>
      {value && <Button type="unstyled" onClick={() => onEdit(stepNumber)}>{value}</Button>}
    </div>
  );

  const dataRowByType = () => {
    const lifespan = convertArea(tool.toolConfig?.customToolLifeSpan || tool.toolConfig?.toolLifespan);
    const coverageArea = convertArea(tool?.coverageArea);
    const [, toolsPerPack] = dimrTool?.name?.match(/(\d)PCS/) || ['', '1'];

    switch (type) {
      case 'CHEMICAL':
        return [
          toTopDataRow(dimrTool?.name),
          toSimpleDataRow('Area coverage', `${coverageArea} ${units.area}`),
          <hr key="hr" />,
          toSimpleDataRow('Product size', `${extractLiters(dimrTool?.name) || extractGallons(dimrTool?.name)} ${units.volume}`),
          toSimpleDataRow('Quantity', `${calculateQuantity(site, coverageArea)} items`),
        ];
      case 'HOLDER':
        return [
          toTopDataRow(dimrTool?.name, ''),
          toSimpleDataRow('', ''),
          <hr key="hr" />,
          toSimpleDataRow('', ''),
          toSimpleDataRow('Quantity', `${tool.toolQuantity} items`),
        ];
      default:
        return [
          toTopDataRow(dimrTool?.name, ''),
          toSimpleDataRow('Tool life', `${lifespan} ${units.area}`),
          <hr key="hr" />,
          toSimpleDataRow('', ''),
          toSimpleDataRow('Quantity', `${
            calculateQuantity(site, lifespan, tool.toolConfig?.toolQuantity, parseInt(toolsPerPack, 10))
          } items`),
        ];
    }
  };

  return (
    <div className="recipe-item">
      <div className="row heading bold">
        <span className="padded">{stepNumber + 1}</span>
        <span>|</span>
        <span className="padded">{type}</span>
        <span className="product-number">{dimrTool?.connectorTypeId ? machineModelId : ''}</span>
      </div>
      <div className="row tool">
        <div>
          <div className="img-card">
            <img src={dimrTool?.imageURL} alt="img" />
            <span>{formatProductNumber(dimrTool?.productNumber)}</span>
          </div>
        </div>
        <div className="three">
          {dataRowByType().map(row => (row))}
        </div>
      </div>
    </div>
  );
};

export default RecipeItem;
