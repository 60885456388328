import './Select.scss';
import cn from 'classnames';

export type Option = {
  label: string,
  value?: string,
  disabled?: boolean,
  key?: string,
};

export type SelectProps = {
  label: string,
  options: Option[],
  darkMode?: boolean,
  value?: any,
  block?: boolean,
  onChangeListener?: Function,
};

const Select = ({ label, options, darkMode, value, block, onChangeListener }: SelectProps) => {
  const classes = cn('hui-select', {
    'hui-select--dark': darkMode,
    'hui-select--block': block,
  });
  return (
    <div className={classes}>
      <select
        className="hui-select__select"
        onChange={(e) => onChangeListener && onChangeListener(e)}
        value={value}
      >
        {options.map((option) => {
          return (
            <option key={option.key} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          );
        })}
      </select>
      <label className="hui-select__label">{label}</label>
    </div>
  );
};

export default Select;
