/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIEnabledSlice, APIRoutes, RejectValue, toolSelectorAPI, withQuery } from '@utils/tool-selector-api';

interface ToolConfigurationSlice extends APIEnabledSlice {
  items: ToolConfig[];
}

export interface ToolConfig {
  connectorTypeId: string;
  toolLifespan: number;
  toolQuantity: number;
  maxToolQuantity: number;
  customToolLifeSpan?: number;
}

const initialState: ToolConfigurationSlice = {
  items: [],
  isFetching: false,
};

export interface GetToolConfigurationsParams {
  machineModelId: string;
  toolSelectionId: string;
}
export const getToolConfigurations = createAsyncThunk<
ToolConfig[],
GetToolConfigurationsParams,
RejectValue
>(
  `GET ${APIRoutes.Configurations.valid}`,
  async (query, thunkAPI) => toolSelectorAPI(
    thunkAPI,
    withQuery(APIRoutes.Configurations.valid, query), {},
  ),
);

const toolConfigurationSlice = createSlice({
  name: 'toolConfiguration',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers:
  builder => {
    builder.addCase(getToolConfigurations.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getToolConfigurations.fulfilled, (state, action) => {
      const { payload } = action;
      state.items = payload;
      state.isFetching = false;
    });
    builder.addCase(getToolConfigurations.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const toolConfigurationsSelector = ({ toolConfiguration }: { toolConfiguration: ToolConfigurationSlice }) => toolConfiguration.items;

export const {
  reset,
} = toolConfigurationSlice.actions;

export default toolConfigurationSlice.reducer;
