import './StepIndicator.scss';

type Step = {
  step: string;
  name: string;
};
interface StepIndicatorProps {
  steps?: Step[],
  currentStep?: string
}

const defaultProps: StepIndicatorProps = {
  steps: undefined,
  currentStep: undefined,
};

const StepIndicator = (props: StepIndicatorProps) => {

  const { steps, currentStep } = props;

  const isActive = (stepIndex: number) => stepIndex <= (steps?.reduce((a, s, i) => (s.step === currentStep ? i : a), 0) || 0) ? 'active' : '';
  const isCurrent = (step: Step) => step.step === currentStep ? 'current' : '';

  return (
    <ul id="step-indicator" className="step-indicator">
      {steps?.map((s, i) => (
        <li key={s.name} className={`${[isActive(i), isCurrent(s)].filter(c => !!c).join(' ')}`}>
          {s.name}
        </li>
      ))}
    </ul>
  );
};

StepIndicator.defaultProps = defaultProps;

export default StepIndicator;
