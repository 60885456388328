import { useDispatch, useSelector } from 'react-redux';
import {
  getToolFamilies as getToolFamiliesAction,
  getToolFamilyWithProduct as getToolFamilyWithProductAction,
  toolFamiliesSelector,
  toolFamilySelector,
} from '@store/toolsSlice';

export const useToolFamily = (selectedToolFamilyId?: string) => {

  const toolFamily = useSelector(toolFamilySelector(selectedToolFamilyId));
  return {
    toolFamily,
  };
};

export const useToolFamilies = (machine?: string, toolSystem?: string, type?: string) => {
  const dispatch = useDispatch();

  const toolFamilies = useSelector(toolFamiliesSelector);
  const getToolFamilies = () => dispatch(getToolFamiliesAction({ machine, toolSystem, type }));
  const getToolFamilyWithProduct = (toolFamilyId: string, machineModelId?: string) => dispatch(getToolFamilyWithProductAction({ toolFamilyId, machineModelId }));

  return {
    toolFamilies,
    getToolFamilies,
    getToolFamilyWithProduct,
  };
};
