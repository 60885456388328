import { ChangeEvent, useState } from 'react';
import useSelection from '@hooks/useSelection';
import useRecipes from '@hooks/useRecipes';
import Input from '@components/common/Input/Input';
import Button from '@components/common/Button/Button';
import { validateNumber } from '@utils/input-validators';
import { MarketType } from '@utils/formatting';
import './SiteSelection.scss';

const SiteSelection = () => {
  const [siteSize, setSiteSize] = useState('0');
  const [error, setError] = useState('');
  const { market } = useRecipes();
  const { makeSelection } = useSelection();

  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    try {
      if (!validateNumber(value)) {
        throw new Error('Invalid site area');
      }
      setSiteSize(value);
      setError('');
    } catch (err: any) {
      setError(err?.message);
    }
  };

  return (
    <div className="site-selection">
      <Input error={error} label={`Site area (${market === MarketType.US ? 'ft' : 'm'}²)`} onChange={onInput} />
      <Button type="primary" block disabled={!!error || siteSize === '0'} onClick={() => makeSelection({ id: siteSize, name: siteSize })} text="Continue" />
    </div>
  );
};

export default SiteSelection;
