/* eslint-disable no-param-reassign */
import { APIEnabledSlice, APIRoutes, RejectValue, toolSelectorAPI } from '@utils/tool-selector-api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CardDisplay } from '@components/common/Card/Card';

interface MaterialsSlice extends APIEnabledSlice{
  items: MaterialType[]
}

const initialState: MaterialsSlice = {
  isFetching: false,
  items: [],
};

export interface MaterialType {
  id: string;
  name: string;
  description: string;
  uiOrder?: string;
  materials: Array<Material>;
}

interface Material extends CardDisplay {}

export const getMaterials = createAsyncThunk<
MaterialType[],
void,
RejectValue
>(
  `GET ${APIRoutes.Materials.getAll}`,
  async (_void, thunkAPI) => toolSelectorAPI(thunkAPI, APIRoutes.Materials.getAll, {}),
);

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers:
  builder => {
    builder.addCase(getMaterials.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getMaterials.fulfilled, (state, action) => {
      const { payload } = action;
      state.items = payload;
      state.isFetching = false;
    });
    builder.addCase(getMaterials.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const materialsSelector = ({ materials }: { materials: MaterialsSlice }) => materials.items;
export const isFetchingSelector = ({ materials }: { materials: MaterialsSlice }) => materials.isFetching;

export const {
  reset,
} = materialsSlice.actions;

export default materialsSlice.reducer;
