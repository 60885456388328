/* eslint-disable no-param-reassign */
import { APIEnabledSlice, APIRoutes, RejectValue, toolSelectorAPI, withQuery } from '@utils/tool-selector-api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface ToolSystem {
  id: string,
  name: string,
  uiOrder: string,
  default: boolean
}
interface MachinesSlice extends APIEnabledSlice{
  items: Machine[];
  toolSystems: ToolSystem[];
}

const initialState: MachinesSlice = {
  isFetching: false,
  items: [],
  toolSystems: [],
};

export interface Machine {
  id: string;
  name: string;
  imageId: string;
  powerSystem: string;
  productNumber: string;
  discs: number;
  diameter?: string;
  configs?: Array<MachineConfig>;
  imageURL: string;
  isPublic: boolean;
}

interface MachineConfig {
  id: string
  connectorTypeId: string
  toolQuantity: string
  diameter?: string;
}

export const getMachines = createAsyncThunk<
Machine[],
void,
RejectValue
>(
  `GET ${APIRoutes.Machines.getAll}`,
  async (_void, thunkAPI) => toolSelectorAPI(thunkAPI, APIRoutes.Machines.getAll, {}),
);

export const getToolSystems = createAsyncThunk<
{ toolSystems: ToolSystem[] },
string,
RejectValue
>(
  `GET ${APIRoutes.Machines.getToolSystem}`,
  async (machineModelId, thunkAPI) => toolSelectorAPI(
    thunkAPI,
    withQuery(APIRoutes.Machines.getToolSystem, { machineModelId }), {},
  ),
);

const machinesSlice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    reset: () => initialState,
    resetToolSystems: (state) => ({ ...state, toolSystems: [] }),
  },
  extraReducers:
  builder => {
    builder.addCase(getMachines.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getMachines.fulfilled, (state, action) => {
      const { payload } = action;
      state.items = payload;
      state.isFetching = false;
    });
    builder.addCase(getMachines.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(getToolSystems.fulfilled, (state, action) => {
      const { payload } = action;
      state.toolSystems = [...payload?.toolSystems]
        .sort((t1, t2) => (parseInt(t1.uiOrder, 10) - parseInt(t2.uiOrder, 10)));
    });
  },
});

export const machinesSelector = ({ machines }: { machines: MachinesSlice }) => machines.items;
export const toolSystemSelector = ({ machines }: { machines: MachinesSlice }) => machines.toolSystems;
export const isFetchingSelector = ({ machines }: { machines: MachinesSlice }) => machines.isFetching;

export const {
  reset,
  resetToolSystems,
} = machinesSlice.actions;

export default machinesSlice.reducer;
