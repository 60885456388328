/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIEnabledSlice, APIRoutes, RejectValue, toolSelectorAPI } from '@utils/tool-selector-api';

interface SessionSlice extends APIEnabledSlice {
  sessionId?: string,
  isCountrySet: boolean,
}

const initialState: SessionSlice = {
  isFetching: false,
  isCountrySet: false,
};

export interface CreateSessionPayload {
  sessionId: string
}

export const createSession = createAsyncThunk<
CreateSessionPayload,
void,
RejectValue
>(
  `POST ${APIRoutes.Session.anonymous}`,
  async (_void, thunkAPI) => toolSelectorAPI(thunkAPI, APIRoutes.Session.anonymous, {
    method: 'POST',
  }),
);

export const setCountry = createAsyncThunk<
unknown,
string,
RejectValue
>(
  `PATCH ${APIRoutes.Session.country}`,
  async (countryCode, thunkAPI) => toolSelectorAPI(thunkAPI, APIRoutes.Session.country, {
    method: 'PATCH',
    body: JSON.stringify({
      countryCode,
    }),
  }),
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers:
  builder => {
    // createSession
    builder.addCase(createSession.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createSession.fulfilled, (state, action) => {
      const { payload } = action;
      state.sessionId = payload.sessionId;
      state.isFetching = false;
    });
    builder.addCase(createSession.rejected, (state) => {
      state.isFetching = false;
    });

    // setCountry
    builder.addCase(setCountry.fulfilled, (state) => {
      state.isCountrySet = true;
    });
  },
});

export const sessionIdSelector = ({ session }: { session: SessionSlice }) => session.sessionId;
export const isCountrySetSelector = ({ session }: { session: SessionSlice }) => session.isCountrySet;

export const {
  reset,
} = sessionSlice.actions;

export default sessionSlice.reducer;
