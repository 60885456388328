import './Grid.scss';

interface GridProps {
  id?: string;
  columns: number;
  children?: JSX.Element | JSX.Element[];
}
const Grid = ({ id, columns, children }: GridProps) => {
  return (
    <section id={id} role="grid" className="grid">
      <div className={`column column-${columns}`}>
        {children}
      </div>
    </section>
  );
};

Grid.defaultProps = {
  id: '',
  children: [],
};

export default Grid;
