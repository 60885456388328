import FloorGrindingRouteWrapper from '@components/FloorGrinding/RouteWrapper';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

const Router = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/:market/:material?/:finish?/:site?/:machine?/:toolSystem?">
          <FloorGrindingRouteWrapper />
        </Route>
        <Route exact path="/">
          <Redirect to="/global" />
        </Route>
      </Switch>
    </HashRouter>
  );
};

export default Router;
