import { useDispatch, useSelector } from 'react-redux';
import {
  getConfig as getConfigAction,
  configSelector,
} from '@store/configSlice';

const useConfig = () => {
  const dispatch = useDispatch();

  const config = useSelector(configSelector);
  const getConfig = () => dispatch(getConfigAction());
  return {
    config,
    getConfig,
  };
};

export default useConfig;
