import { useDispatch, useSelector } from 'react-redux';
import {
  getTool as getToolAction,
  addToTools as addToToolsAction,
  toolSelector,
  AddToToolsPayload,
} from '@store/toolsSlice';

const useTool = (toolId: string) => {
  const dispatch = useDispatch();

  const tool = useSelector(toolSelector(toolId));
  const getTool = (id?: string) => dispatch(getToolAction(id || toolId));
  const addToTools = (payload: AddToToolsPayload) => dispatch(addToToolsAction(payload));

  return {
    tool,
    getTool,
    addToTools,
  };
};

export default useTool;
