export enum MarketType {
  AU = 'au',
  BR = 'brazil',
  US = 'us',
  GLOBAL = 'global',
}

export const formatProductNumber = (productNumber: string) => productNumber
  ?.match(/(\d{3})(\d{2})(\d{2})(\d{2})/)
  ?.slice(1, 5)
  ?.reduce((a, s, i, r) => `${a}${i === r.length - 1 ? '-' : ' '}${s}`);

export const calculateQuantity = (
  siteSize: string | undefined,
  lifespan: number = 1,
  numPerLifespan: number = 1,
  toolsPerPack: number = 1,
) => `${Math.ceil((Math.ceil(parseInt(siteSize || '0', 10) / lifespan) * numPerLifespan) / toolsPerPack)}`;

export const extractLiters = (name?: string) => name?.match(/(\d+)L/)?.pop();
export const extractGallons = (name?: string) => name?.match(/(\d+)G/)?.pop();

const metric = {
  units: { volume: 'L', area: 'm²' },
  convertArea: (meters = 0) => meters,
};
export const marketFormats = {
  undefined: metric,
  [MarketType.GLOBAL]: metric,
  [MarketType.AU]: metric,
  [MarketType.BR]: metric,
  [MarketType.US]: {
    units: { volume: 'gal.', area: 'ft²' },
    convertArea: (meters = 0) => Math.floor(10.76391 * (meters)),
  },
};

export const configurationName = (qty: number = 1, max: number = 1) => {
  const set = (max / qty);
  if (set === 1) {
    return 'Full set';
  } if (set === 2) {
    return 'Half set';
  }
  return `${qty} / ${max}`;

};
