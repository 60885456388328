import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import config from '@store/configSlice';
import selection from '@store/selectionSlice';
import materials from '@store/materialsSlice';
import finishes from '@store/finishesSlice';
import machines from '@store/machinesSlice';
import recipes from '@store/recipesSlice';
import tools from '@store/toolsSlice';
import toolConfiguration from '@store/toolConfigurationSlice';
import session from '@store/sessionSlice';

const reducer = combineReducers({
  config,
  selection,
  materials,
  finishes,
  machines,
  recipes,
  tools,
  toolConfiguration,
  session,
});

export default reducer;

function configureStore() {
  if (process.env.NODE_ENV === 'development') {
    const composedEnhancers = composeWithDevTools(applyMiddleware(thunk, createLogger({ collapsed: true })));

    return createStore(reducer, {}, composedEnhancers);
  }

  return createStore(
    reducer,
    applyMiddleware(thunk),
  );
}
export const store = configureStore();
export type RootState = ReturnType<typeof store.getState>;
