/* eslint-disable no-param-reassign */
import { CardDisplay } from '@components/common/Card/Card';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Step {
  Material = 'material',
  Finish = 'finish',
  Site = 'site',
  Machine = 'machine',
  Selection = 'selection',
}

export interface Selections {
  material?: string;
  finish?: string;
  site?: string;
  machine?: string;
  toolSystem?: string;
}

interface SelectionSlice extends Selections {
  isInitiated: boolean,
  currentStep: Step,
  data: {
    material?: CardDisplay,
    finish?: CardDisplay,
    site?: CardDisplay,
    machine?: CardDisplay,
    toolSystem?: CardDisplay,
  }
}

const initialState: SelectionSlice = {
  isInitiated: false,
  currentStep: Step.Material,
  data: {},
};

export const selectionSteps = [
  { step: Step.Material, name: 'Material' },
  { step: Step.Finish, name: 'Finish' },
  { step: Step.Site, name: 'Job Site' },
  { step: Step.Machine, name: 'Machine' },
  { step: Step.Selection, name: 'Selection' },
];

const machineSelectionSeparator = '&';
export const toMachineSelection = (machineModelId: string, toolSystemId: string) => `${machineModelId}${machineSelectionSeparator}${toolSystemId}`;
export const fromMachineSelection = (machineSelection: string) => {
  const [machineModelId, toolSystemId] = machineSelection.split(machineSelectionSeparator);
  return { machineModelId, toolSystemId };
};

const forward = (state: SelectionSlice, payload: CardDisplay) => {
  if (!payload) {
    return;
  }
  const { currentStep, material, finish, site } = state;

  if (currentStep === Step.Material) {
    delete state.finish;
    delete state.site;
    delete state.machine;
    delete state.toolSystem;
    state.material = payload.id;
    state.data = { material: payload };
    state.currentStep = Step.Finish;
  } else if (currentStep === Step.Finish && !!material) {
    delete state.site;
    delete state.machine;
    delete state.toolSystem;
    state.finish = payload.id;
    state.data.finish = payload;
    state.currentStep = Step.Site;
  } else if (currentStep === Step.Site && !!material && !!finish) {
    delete state.machine;
    delete state.toolSystem;
    state.site = payload.id;
    state.data.site = payload;
    state.currentStep = Step.Machine;
  } else if (currentStep === Step.Machine && !!material && !!finish && !!site) {
    const { machineModelId, toolSystemId } = fromMachineSelection(payload.id);
    state.machine = machineModelId;
    state.toolSystem = toolSystemId;

    state.data.machine = payload;
    state.data.toolSystem = { id: toolSystemId, name: toolSystemId };
    state.currentStep = Step.Selection;
  }
};

const backward = (state: SelectionSlice) => {
  const { currentStep } = state;
  switch (currentStep) {
    case Step.Finish:
      delete state.material;
      delete state.data.material;
      state.currentStep = Step.Material;
      break;
    case Step.Site:
      delete state.finish;
      delete state.data.finish;
      state.currentStep = Step.Finish;
      break;
    case Step.Machine:
      delete state.site;
      delete state.data.site;
      state.currentStep = Step.Site;
      break;
    case Step.Selection:
      delete state.machine;
      delete state.toolSystem;
      delete state.data.machine;
      delete state.data.toolSystem;
      state.currentStep = Step.Machine;
      break;
    default:
      state.currentStep = initialState.currentStep;
      break;
  }
};

const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<Selections>) => {
      if (state.isInitiated) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { payload } = action;
      const { material, finish, site, machine, toolSystem } = payload;

      if (material) {
        forward(state, { id: material, name: material });
        if (finish) {
          forward(state, { id: finish, name: finish });
          if (site) {
            forward(state, { id: site, name: site });
            if (machine && toolSystem) {
              forward(state, { id: toMachineSelection(machine, toolSystem), name: '' });
            }
          }
        }
      }

      state.isInitiated = true;
    },
    makeSelection: (state, action: PayloadAction<CardDisplay>) => {
      const { payload } = action;
      forward(state, payload);
    },
    undoSelection: (state) => {
      backward(state);
    },
  },
});

export const materialSelector = ({ selection }: { selection: SelectionSlice }) => selection.material;
export const finishSelector = ({ selection }: { selection: SelectionSlice }) => selection.finish;
export const siteSelector = ({ selection }: { selection: SelectionSlice }) => selection.site;
export const machineSelector = ({ selection }: { selection: SelectionSlice }) => selection.machine;
export const toolSystemSelector = ({ selection }: { selection: SelectionSlice }) => selection.toolSystem;
export const dataSelector = ({ selection }: { selection: SelectionSlice }) => selection.data;

export const currentStepSelector = ({ selection }: { selection: SelectionSlice }) => selection.currentStep;
export const isInitiaedSelector = ({ selection }: { selection: SelectionSlice }) => selection.isInitiated;

export const {
  reset,
  init,
  makeSelection,
  undoSelection,
} = selectionSlice.actions;

export default selectionSlice.reducer;
