import { useDispatch, useSelector } from 'react-redux';
import {
  getMaterials as getMaterialsAction, materialsSelector,
} from '@store/materialsSlice';

const useMaterials = () => {
  const dispatch = useDispatch();

  const materials = useSelector(materialsSelector);
  const getMaterials = () => dispatch(getMaterialsAction());

  return {
    materials,
    getMaterials,
  };
};

export default useMaterials;
