import cls from 'classnames';
import { ReactComponent as SvgSpinnerIcon } from './Spinner.svg';
import './Spinner.scss';
import Modal from '../Modal/Modal';

export interface Props {
  baseColor?: string;
  fillColor?: string;
  size?: string;
  className?: string;
  title?: string;
}

const Spinner = (props: Props) => {
  return (
    <Modal>
      <span className={cls('Spinner', props.className)} title={props.title}>
        <SvgSpinnerIcon
          className={cls(
            'spinner',
            `base-${props.baseColor} fill-${props.fillColor}`,
          )}
          width={props.size}
          height={props.size}
            />
      </span>
    </Modal>
  );
};

Spinner.defaultProps = {
  baseColor: 'grey300',
  fillColor: 'orange500',
  size: '6vw',
};

export default Spinner;
