import { useDispatch, useSelector } from 'react-redux';
import {
  init as initAction,
  makeSelection as makeSelectionAction,
  undoSelection as undoSelectionAction,
  currentStepSelector,
  materialSelector,
  finishSelector,
  siteSelector,
  machineSelector,
  toolSystemSelector,
  dataSelector,
  Selections,
  selectionSteps,
  isInitiaedSelector,
} from '@store/selectionSlice';
import { CardDisplay } from '@components/common/Card/Card';

const useSelection = () => {
  const dispatch = useDispatch();

  const material = useSelector(materialSelector);
  const finish = useSelector(finishSelector);
  const site = useSelector(siteSelector);
  const machine = useSelector(machineSelector);
  const toolSystem = useSelector(toolSystemSelector);
  const data = useSelector(dataSelector);

  const currentStep = useSelector(currentStepSelector);
  const isInitiated = useSelector(isInitiaedSelector);
  const init = (selections: Selections) => dispatch(initAction(selections));
  const makeSelection = (selection: CardDisplay) => dispatch(makeSelectionAction(selection));
  const undoSelection = () => dispatch(undoSelectionAction());

  return {
    material,
    finish,
    site,
    machine,
    toolSystem,
    data,
    isInitiated,
    init,
    currentStep,
    selectionSteps,
    makeSelection,
    undoSelection,
  };
};

export default useSelection;
