import { useEffect } from 'react';
import { Selections, Step } from '@store/selectionSlice';
import useConfig from '@hooks/useConfig';
import useSession from '@hooks/useSession';
import useSelection from '@hooks/useSelection';
import useSpinner from '@hooks/useSpinner';
import useRecipes from '@hooks/useRecipes';
import StepIndicator from '@components/common/StepIndicator/StepIndicator';
import FinishSelection from '@components/FinishSelection/FinishSelection';
import MaterialSelection from '@components/MaterialSelection/MaterialSelection';
import SiteSelection from '@components/SiteSelection/SiteSelection';
import MachineSelection from '@components/MachineSelection/MachineSelection';
import RecipeDisplay from '@components/RecipeDisplay/RecipeDisplay';
import Spinner from '@components/common/Spinner/Spinner';
import Button from '@components/common/Button/Button';
import { Icons } from '@components/common/Icon/Icon';
import { MarketType } from '@utils/formatting';
import './FloorGrinding.scss';

interface FloorGrindingProps extends Selections {
  market?: MarketType;
  countryCode?: string;
}

const defaultProps: FloorGrindingProps = {
  market: MarketType.GLOBAL,
  material: undefined,
  finish: undefined,
  site: undefined,
  machine: undefined,
  toolSystem: undefined,
  countryCode: undefined,
};

const FloorGrinding = (props: FloorGrindingProps) => {

  const { market = MarketType.GLOBAL, material, finish, site, machine, toolSystem, countryCode = 'EU' } = props;

  const { config, getConfig } = useConfig();
  const { sessionId, createSession, isCountrySet, setCountry } = useSession();

  const { selectionSteps, isInitiated, init, currentStep, undoSelection } = useSelection();
  const { showSpinner } = useSpinner();
  const { setMarket, exportRecipe } = useRecipes();

  useEffect(() => {
    getConfig();
    createSession();
    setMarket(market);
  }, []);

  useEffect(() => {
    if (sessionId && !isCountrySet) {
      setCountry(countryCode);
    }
  }, [sessionId, isCountrySet]);

  useEffect(() => {
    if (!isInitiated) {
      init({ material, finish, site, machine, toolSystem });
    }
  }, [props, isInitiated, init]);

  const canUseAPI = config?.apiKey && isCountrySet;

  return (
    <div className="floor-grinding">
      {showSpinner ? <Spinner /> : null}
      <div className="flex-column">
        <div className="one grow">
          {currentStep !== Step.Material && (
            <Button type="ghost" icon={{ id: Icons.arrow_back, leftAligned: true }} onClick={undoSelection} />
          )}
        </div>
        <div className="one grow">
          <h1>Floor Grinding Selection</h1>
        </div>
        <div className="one">
          {/* {currentStep !== Step.Material && (
            <Button type="ghost" icon={{ id: Icons.info, leftAligned: false }} onClick={() => {}} />
          )} */}
        </div>
      </div>
      <div className="flex-row">
        <StepIndicator steps={selectionSteps} currentStep={currentStep} />
      </div>
      <hr />
      <div className="flex-row content">
        {canUseAPI && currentStep === Step.Material && <MaterialSelection />}
        {canUseAPI && currentStep === Step.Finish && <FinishSelection />}
        {canUseAPI && currentStep === Step.Site && <SiteSelection />}
        {canUseAPI && currentStep === Step.Machine && <MachineSelection />}
        {canUseAPI && currentStep === Step.Selection && <RecipeDisplay />}
      </div>
      {currentStep === Step.Selection && (
        <div className="floating">
          <Button type="secondary" block icon={{ id: Icons.export, leftAligned: false }} onClick={exportRecipe}>EXPORT</Button>
        </div>
      )}
    </div>
  );
};

FloorGrinding.defaultProps = defaultProps;

export default FloorGrinding;
