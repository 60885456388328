import { useDispatch, useSelector } from 'react-redux';
import {
  getFinishes as getFinishesAction, finishesSelector,
} from '@store/finishesSlice';

const useFinishes = () => {
  const dispatch = useDispatch();

  const finishes = useSelector(finishesSelector);
  const getFinishes = () => dispatch(getFinishesAction());

  return {
    finishes,
    getFinishes,
  };
};

export default useFinishes;
