import { ReactComponent as ArrowBack } from './arrow_back.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as Info } from './info.svg';

export enum Icons {
  arrow_back = 'arrow_back',
  export = 'export',
  info = 'info',
}

const icons = {
  [Icons.arrow_back]: ArrowBack,
  [Icons.export]: Export,
  [Icons.info]: Info,
};
interface IconProps {
  icon: Icons;
  className: string;
}

export default ({ icon, ...props }: IconProps) => {

  const Icon = icons[icon];
  return (
    <span className={props.className}>
      <Icon />
    </span>
  );
};
