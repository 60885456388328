import { useEffect, useState } from 'react';
import Grid from '@components/common/Grid/Grid';
import Modal from '@components/common/Modal/Modal';
import useRecipes from '@hooks/useRecipes';
import useSelection from '@hooks/useSelection';
import RecipeItem from './RecipeItem';
import EditRecipeItem from './EditRecipeItem';
import './RecipeDisplay.scss';

const RecipeDisplay = () => {
  const [editIndex, setEditIndex] = useState<{ recipeIndex: number, stepIndex: number }>();
  const [showModal, setShowModal] = useState(false);

  const { recipes, getRecipes } = useRecipes();
  const { material, finish, machine, toolSystem } = useSelection();

  useEffect(() => {
    if (!!material && !!finish && !!machine && !!toolSystem) {
      getRecipes({ material, finish, machine, toolSystem });
    } else {
      throw new Error('All required selections have not been made!');
    }
  }, []);

  const editRecipeItem = (recipeIndex: number) => (stepIndex: number) => {
    setEditIndex({ recipeIndex, stepIndex });
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal
      && (
        <Modal>
          <div className="recipe-modal">
            <EditRecipeItem
              recipeIndex={editIndex?.recipeIndex}
              stepIndex={editIndex?.stepIndex}
              onClose={onCloseModal}
            />
          </div>
        </Modal>
      )}
      <a className="holder-reference" href="https://issuu.com/husqvarna_construction/docs/husqvarna_tool_holder_guide" target="_blank" rel="noreferrer">
        <div className="recipe-info">
          ⚠️ Make sure you are equipped with suitable tool holders for the EZ tool system ⚠️<br />
          🔗 Click here to see the Machine - Tool Holder reference sheet 🔗
        </div>
      </a>
      {recipes?.length > 0
        ? recipes?.map((recipe, i) => (
          <div key={recipe.id}>
            <Grid id="recipes-grid" columns={1}>
              <article className="recipe">
                {/* <h1>Recipe</h1> */}
                {recipe?.steps
                  .filter(step => !step?.inactive)
                  .map((step, stepIndex) => (
                    <RecipeItem
                      key={step.id}
                      stepNumber={stepIndex}
                      machineModelId={recipe?.machineModelId}
                      onEdit={editRecipeItem(i)}
                      {...step} />
                  ))}
              </article>
            </Grid>
          </div>))
        : <p>No recipes found</p>}
    </>
  );
};

export default RecipeDisplay;
