/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '@components/common/Button/Button';
import Input from '@components/common/Input/Input';
import useRecipes from '@hooks/useRecipes';
import { useToolFamily, useToolFamilies } from '@hooks/useToolFamilies';
import useTool from '@hooks/useTool';
import useSelection from '@hooks/useSelection';
import useToolConfiguration from '@hooks/useToolConfiguration';
import { RecipeStep } from '@store/recipesSlice';
import { ToolConfig } from '@store/toolConfigurationSlice';
import Select from '@components/common/Select/Select';
import { validateNumber } from '@utils/input-validators';
import { formatProductNumber } from '@utils/formatting';
import './EditRecipeItem.scss';
// import { useSelector } from 'react-redux';
// import { toolSelector } from '@store/toolsSlice';

interface EditRecipeItemProps {
  onClose: () => void;
  recipeIndex: number | undefined;
  stepIndex: number | undefined;
}
const EditRecipeItem = ({ onClose, recipeIndex = 0, stepIndex = 0 }: EditRecipeItemProps) => {

  const { recipes, editRecipe } = useRecipes();
  const [recipeStep] = useState<RecipeStep>(recipes[recipeIndex]?.steps[stepIndex]);
  const [toolLife, setToolLife] = useState<number | undefined>();
  const { machine, toolSystem } = useSelection();
  const { toolFamilies, getToolFamilies, getToolFamilyWithProduct } = useToolFamilies(machine, toolSystem, recipeStep?.type);

  const [selectedToolId, setSelectedToolId] = useState(recipeStep?.tool?.dimrToolId);
  const { tool, getTool, addToTools } = useTool(selectedToolId);

  const [selectedToolFamily, setSelectedToolFamily] = useState('');
  const { toolFamily } = useToolFamily(selectedToolFamily);

  const [inputError, setInputError] = useState('');
  const [selectedToolConfiguration, setSelectedToolConfiguration] = useState('');
  const [toolConfigurations, setToolConfigurations] = useState<ToolConfig[]>([]);
  const { toolConfigurations: initialToolConfigurations, getToolConfigurations } = useToolConfiguration();

  useEffect(() => {
    if (recipes?.length > 0 && stepIndex >= 0 && machine) {
      getTool(recipeStep.tool?.dimrToolId);
      getToolConfigurations(machine, recipeStep?.tool?.toolSelectionId);
      if (!toolFamilies?.length) {
        getToolFamilies();
      } else {
        const defaultToolFamily = toolFamilies.find(tf => tf.name === recipeStep?.name);
        if (defaultToolFamily?.id) {
          getToolFamilyWithProduct(defaultToolFamily.id, machine);
          setSelectedToolFamily(defaultToolFamily?.id);
        }
      }

      setToolLife(recipeStep?.tool?.toolConfig?.customToolLifeSpan || recipeStep?.tool?.toolConfig?.toolLifespan);
      setSelectedToolId(recipeStep?.tool?.dimrToolId);
      setInputError('');
      setSelectedToolConfiguration(`${recipeStep?.tool?.toolConfig?.toolQuantity}`);
    }
  }, [recipes, stepIndex, recipeStep]);

  useEffect(() => {
    if (initialToolConfigurations) {
      setToolConfigurations(initialToolConfigurations);
    }
  }, [initialToolConfigurations]);

  useEffect(() => {
    if (toolFamilies && !toolFamily) {
      const defaultToolFamily = toolFamilies.find(tf => tf.name === recipeStep?.name);
      if (defaultToolFamily?.id) {
        getToolFamilyWithProduct(defaultToolFamily.id, machine);
        setSelectedToolFamily(defaultToolFamily?.id);
      }
    }
  }, [toolFamilies]);

  const onCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    getToolFamilyWithProduct(selectedId, machine);
    setSelectedToolFamily(selectedId);
    setSelectedToolId('');
  };

  const onToolChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    setSelectedToolId(selectedId);
    addToTools({ toolFamilyId: toolFamily!.id, toolId: selectedId });
    const selectedTool = toolFamily?.tools.find(t => t?.product?.id === selectedId);
    getTool(selectedId);
    if (selectedTool?.configurations) {
      setToolConfigurations(selectedTool.configurations);
    }
  };

  const onToolLifeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    try {
      if (!validateNumber(value)) {
        throw new Error('Invalid tool life');
      }
      setToolLife(parseInt(e.target.value, 10));
      setInputError('');
    } catch (err: any) {
      if (!value) {
        setToolLife(undefined);
      }
      setInputError(err?.message);
    }
  };

  const onToolConfigurationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    setSelectedToolConfiguration(selectedId);
  };

  const onSave = () => {
    if (toolFamily?.id && selectedToolId && selectedToolConfiguration) {
      const { id, product } = toolFamily?.tools.find(t => t?.product?.id === selectedToolId) || {};
      const toolConfigQuantity = parseInt(selectedToolConfiguration, 10);
      const toolConfig = toolConfigurations.find(tc => tc.toolQuantity === toolConfigQuantity) || toolConfigurations[0];

      if (product && toolConfig && toolSystem) {
        editRecipe({
          recipeIndex,
          stepIndex,
          step: {
            id: toolFamily!.id,
            name: toolFamily!.name,
            type: toolFamily!.type,
            toolSystemId: toolSystem,
            tool: {
              dimrToolId: selectedToolId,
              toolSelectionId: id || 'N/A',
              ...product,
              toolConfig: {
                ...toolConfig,
                customToolLifeSpan: toolLife || toolConfig.toolLifespan,
              },
            },
          },
        });
      } else {
        throw new Error('Updating recipe failed!');
      }
      onClose();
    }
  };

  const isUpdateEnabled = () => toolFamily?.id
    ? !!selectedToolId
    : (!inputError
      && toolLife !== recipeStep?.tool?.toolConfig?.customToolLifeSpan
      && toolLife !== recipeStep?.tool?.toolConfig?.toolLifespan
      && parseInt(selectedToolConfiguration, 10) !== recipeStep?.tool?.toolConfig?.toolQuantity);

  return (
    <>
      <div className="header">
        <h2>Edit Selection</h2>
        <Button type="ghost" onClick={onClose}>X</Button>
      </div>
      <div className="edit-recipe-item">
        <div className="row heading bold">
          <span className="padded">{stepIndex + 1}</span>
          <span>|</span>
          <span className="padded">{recipeStep?.type}</span>
        </div>

        <div className="row tool">
          <div>
            <div className="img-card">
              <img src={tool?.imageURL || `https://via.placeholder.com/200/f4f4f4/f25420.webp?text=${toolFamily?.name}`} alt="Missing" />
            </div>
          </div>
          <div>
            <div className="row">
              <span>{tool?.name}</span>
            </div>
            <div className="row">
              <span>{formatProductNumber(tool?.productNumber)}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <Select
            label="Category"
            block
            value={toolFamily?.id || 'category'}
            options={[
              { key: 'category', value: 'category', label: 'Choose category', disabled: true },
              ...toolFamilies.map(tf => ({
                key: tf.id,
                value: tf.id,
                label: tf.name,
              }))]}
            onChangeListener={onCategoryChange}
          />
          {toolFamilies && toolFamily && (
            <div className="row">
              <Select
                label="Tools"
                block
                value={selectedToolId || 'tool'}
                options={[
                  { key: 'tool', value: 'tool', label: 'Choose tool', disabled: true },
                  ...toolFamily!.tools
                    .map(({ product }) => ({
                      key: product?.id || '',
                      value: product?.id || '',
                      label: product?.name || '',
                    }))
                    .filter(t => t.key && t.value && t.label),
                ]}
                onChangeListener={onToolChange}
              />
            </div>
          )}
        </div>

        {toolConfigurations && (
          <div className="row">
            <Select
              label="Tool Configuration"
              block
              value={selectedToolConfiguration}
              options={[
                ...toolConfigurations
                  .map((toolConfig, i) => ({
                    key: `${toolConfig?.toolQuantity}${i}`,
                    value: `${toolConfig?.toolQuantity}`,
                    label: `${toolConfig?.toolQuantity}/${toolConfig?.maxToolQuantity}`,
                  }))
                  .filter(t => t.key && t.value && t.label),
              ]}
              onChangeListener={onToolConfigurationChange}
            />
          </div>
        )}
        <Input label="Tool life" error={inputError} value={toolLife} onChange={onToolLifeChange} />
      </div>
      <Button type="primary" disabled={!isUpdateEnabled()} onClick={onSave}>UPDATE</Button>
    </>
  );
};

export default EditRecipeItem;
