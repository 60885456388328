import { useDispatch, useSelector } from 'react-redux';
import {
  machinesSelector,
  toolSystemSelector,
  getMachines as getMachinesAction,
  getToolSystems as getToolSystemsAction,
  resetToolSystems as resetToolSystemsActions,
} from '@store/machinesSlice';

const useMachines = () => {
  const dispatch = useDispatch();

  const machines = useSelector(machinesSelector);
  const getMachines = () => dispatch(getMachinesAction());

  const toolSystems = useSelector(toolSystemSelector);
  const getToolSystems = (machineModelId: string) => dispatch(getToolSystemsAction(machineModelId));
  const resetToolSystems = () => dispatch(resetToolSystemsActions());

  return {
    machines,
    getMachines,
    toolSystems,
    getToolSystems,
    resetToolSystems,
  };
};

export default useMachines;
