/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIEnabledSlice, APIRoutes, RejectValue, toolSelectorAPI } from '@utils/tool-selector-api';

interface Authorization {
  authorizationEndpoint: string,
  clientId: string,
  redirectUris: {
    WEB: string,
    APP: string,
  },
  scope: string
}

export interface ConfigItem {
  auth: Authorization,
  endpoint: string,
  apiKey: string
  env: string,
}

interface ConfigSlice extends APIEnabledSlice {
  item?: ConfigItem,
}

const initialState: ConfigSlice = {
  isFetching: false,
};

export const getConfig = createAsyncThunk<
ConfigItem,
void,
RejectValue
>(
  `GET ${APIRoutes.Config.get}`,
  async (_void, thunkAPI) => toolSelectorAPI(thunkAPI, APIRoutes.Config.get, {}),
);

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers:
  builder => {
    builder.addCase(getConfig.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getConfig.fulfilled, (state, action) => {
      const { payload } = action;
      state.item = payload;
      state.isFetching = false;
    });
    builder.addCase(getConfig.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const configSelector = ({ config }: { config: ConfigSlice }) => config.item;
export const isFetchingSelector = ({ config }: { config: ConfigSlice }) => config.isFetching;

export const {
  reset,
} = configSlice.actions;

export default configSlice.reducer;
