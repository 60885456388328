import { useDispatch, useSelector } from 'react-redux';
import {
  createSession as createSessionAction,
  setCountry as setCountryAction,
  isCountrySetSelector,
  sessionIdSelector,
} from '@store/sessionSlice';

const useSession = () => {
  const dispatch = useDispatch();

  const sessionId = useSelector(sessionIdSelector);
  const isCountrySet = useSelector(isCountrySetSelector);
  const createSession = () => dispatch(createSessionAction());
  const setCountry = (countryCode: string) => dispatch(setCountryAction(countryCode));

  return {
    sessionId,
    createSession,
    isCountrySet,
    setCountry,
  };
};

export default useSession;
