import { useEffect, useState } from 'react';
import Card from '@components/common/Card/Card';
import Grid from '@components/common/Grid/Grid';
import useMaterials from '@hooks/useMaterials';
import useSelection from '@hooks/useSelection';
import useRecipes from '@hooks/useRecipes';
import { MaterialType } from '@store/materialsSlice';

const MaterialSelection = () => {
  const { materials, getMaterials } = useMaterials();
  const { materialFilters, getMaterialFilters } = useRecipes();
  const { makeSelection } = useSelection();
  const [selectableMaterials, setSelectableMaterials] = useState<MaterialType[]>();

  useEffect(() => {
    getMaterials();
    getMaterialFilters({});
  }, []);

  useEffect(() => {
    if (!!materials?.length && !!materialFilters?.length) {
      const filteredMaterials = materials
        .map(materialType => ({
          ...materialType,
          finishes: materialType.materials.filter(material => materialFilters?.includes(material.id)),
        }))
        .filter(finishType => finishType.finishes.length > 0);
      setSelectableMaterials(filteredMaterials);
    }
  }, [materials, materialFilters]);

  return (
    <div className="material-selection">
      {selectableMaterials?.map(materialType => (
        materialType?.materials?.length > 0
          ? (
            <div key={materialType.name}>
              <h1>{materialType.name?.toUpperCase()}</h1>
              <Grid id="materials-grid" columns={3}>
                {materialType.materials
                  ?.slice()
                  ?.sort((m1, m2) => m1.name.localeCompare(m2.name))
                  ?.map(material => (
                    <Card
                      key={material.id}
                      article={material}
                      onClick={makeSelection}
                    />
                  ))}
              </Grid>
            </div>)
          : null
      ))}
    </div>
  );
};

export default MaterialSelection;
