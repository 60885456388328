import { ChangeEvent } from 'react';
import cn from 'classnames';
import './Input.scss';

export type TextProps = {
  label: string,
  error?: string,
  value?: any,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
};

const Input = ({ label, error, value, onChange }: TextProps) => {

  const id = 'site-input';
  const classes = cn('hui-text', {
    'hui-text--invalid': error,
  });

  return (
    <div className={classes}>
      <input
        id={id}
        type="text"
        className="hui-text__input"
        placeholder=" "
        value={value}
        onChange={onChange}
      />
      <label className="hui-text__label" htmlFor={id}>{label}</label>
      {error && <div className="hui-validation--error">{error}</div>}
    </div>
  );
};

export default Input;
