import { Provider } from 'react-redux';
import { store } from '@store';
import Router from '@components/Router/Router';
import './App.scss';

const App = () => {
  return (
    <Provider store={store}>
      <div className="container">
        <Router />
      </div>
    </Provider>
  );
};

export default App;
