import cn from 'classnames';
import React from 'react';
import Icon, { Icons } from '@components/common/Icon/Icon';
import './button.scss';

export type ButtonSize = 'regular' | 'small' | 'large';
export type ButtonType = 'ghost' | 'tertiary' | 'unstyled' | 'primary' | 'secondary' | 'danger' | 'contrast';

export type ButtonAriaAttributes = Pick<React.AriaAttributes,
'aria-controls' |
'aria-hidden' |
'aria-label' |
'aria-labelledby' |
'aria-haspopup' |
'aria-expanded'
>;

export type ButtonProps = ButtonAriaAttributes & {
  id?: string;
  active?: boolean,
  text?: string,
  size?: ButtonSize,
  type?: ButtonType,
  outlined?: boolean,
  icon?: {
    id: Icons,
    leftAligned?: boolean
  }
  block?: boolean,
  disabled?: boolean,
  className?: string,
  children?: any
  submit?: boolean;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
};

const Button = React.forwardRef<
HTMLButtonElement,
ButtonProps
>((props: ButtonProps, forwardRef) => {
  const {
    id,
    active,
    text,
    size,
    type,
    icon,
    outlined,
    block,
    className,
    disabled,
    submit,
    onClick,
    onBlur,
    onFocus,
  } = props;

  const classes = cn({
    'hui-btn': type !== 'unstyled',
    'hui-unstyled-btn': type === 'unstyled',
  }, {
    'hui-btn--small': size === 'small',
    'hui-btn--large': size === 'large',
    'hui-btn--block': block,
  }, {
    'hui-btn--primary': type === 'primary',
    'hui-btn--secondary': type === 'secondary',
    'hui-btn--danger': type === 'danger',
    'hui-btn--contrast': type === 'contrast',
    'hui-btn--disabled': disabled,
    'hui-btn--ghost': type === 'ghost',
    'hui-btn--tertiary': type === 'tertiary',
  }, {
    'hui-btn--outlined': outlined,
    'hui-btn--active': active,
  }, className);

  const childElements = [
    text && <span key="text" className="hui-text-button">{ text }</span>,
    props.children,
    icon && <Icon key="icon" icon={icon.id} className={cn('hui-btn__icon', { 'hui-btn__icon--left': icon.leftAligned })} />,
  ].filter(c => !!c);

  if (icon?.leftAligned) {
    childElements.reverse();
  }

  return (
    <button
      id={id}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      ref={forwardRef}
      className={classes}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      aria-controls={props['aria-controls']}
      aria-hidden={props['aria-hidden']}
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      aria-haspopup={props['aria-haspopup']}
      aria-expanded={props['aria-expanded']}
    >
      { childElements }
    </button>
  );
});

export default Button;
