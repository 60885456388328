/* eslint-disable no-param-reassign */
import { APIEnabledSlice, APIRoutes, RejectValue, toolSelectorAPI } from '@utils/tool-selector-api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CardDisplay } from '@components/common/Card/Card';

interface FinishesSlice extends APIEnabledSlice{
  items: FinishType[]
}

const initialState: FinishesSlice = {
  isFetching: false,
  items: [],
};

export interface FinishType {
  id: string;
  name: string;
  description?: string;
  finishes: Array<Finish>;
  uiOrder: string;
  isDeletable: boolean;
}

interface Finish extends CardDisplay {
  nameTextItemId?: string;
  descriptionTextItemId?: string;
  subtitle?: string;
  subtitleTextItemId?: string;
  imageURL?: string;
  imageSize?: number;
  uiOrder?: string;
  descriptionTranslation?: string;
  imageId?: string;
  isDeletable: boolean
}

export const getFinishes = createAsyncThunk<
FinishType[],
void,
RejectValue
>(
  `GET ${APIRoutes.Finishes.getAll}`,
  async (_void, thunkAPI) => toolSelectorAPI(thunkAPI, APIRoutes.Finishes.getAll, {}),
);

const finishSlice = createSlice({
  name: 'finishes',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers:
  builder => {
    builder.addCase(getFinishes.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getFinishes.fulfilled, (state, action) => {
      const { payload } = action;
      state.items = payload;
      state.isFetching = false;
    });
    builder.addCase(getFinishes.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const finishesSelector = ({ finishes }: { finishes: FinishesSlice }) => finishes.items;
export const isFetchingSelector = ({ finishes }: { finishes: FinishesSlice }) => finishes.isFetching;

export const {
  reset,
} = finishSlice.actions;

export default finishSlice.reducer;
