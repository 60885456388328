/* eslint-disable no-param-reassign */
import { APIEnabledSlice, APIRoutes, RejectValue, withParams, toolSelectorAPI, withQuery } from '@utils/tool-selector-api';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ToolConfig } from './toolConfigurationSlice';

interface ToolsSlice extends APIEnabledSlice{
  items: { [x: string]: Tool },
  toolFamilies: ToolFamily[],
}

const initialState: ToolsSlice = {
  isFetching: false,
  items: {},
  toolFamilies: [],
};

interface ToolFamilyTool {
  id: string,
  product?: Tool,
  configurations: ToolConfig[],
}
interface ToolFamily {
  id: string,
  name: string,
  description: string,
  gritSize: string,
  type: string,
  toolSystemId: string,
  markets: string[],
  tools: ToolFamilyTool[],
  chemicals: []
}
export interface Tool {
  id: string
  productNumber: string,
  name: string,
  model: string,
  diameter: string,
  imageId: string,
  connectorTypeId: string,
  imageURL: string
}

export const getTool = createAsyncThunk<
Tool,
string,
RejectValue
>(
  `GET ${APIRoutes.Tools.get}`,
  async (toolId, thunkAPI) => toolSelectorAPI(thunkAPI, `${APIRoutes.Tools.get}/${toolId}`, {}),
);

interface GetToolFamiliesThunkArgs {
  machine?: string;
  toolSystem?: string;
  type?: string;
}
export const getToolFamilies = createAsyncThunk<
ToolFamily[],
GetToolFamiliesThunkArgs,
RejectValue
>(
  `GET ${APIRoutes.Tools.getFamilies}`,
  async (selections, thunkAPI) => toolSelectorAPI(thunkAPI, withQuery(APIRoutes.Tools.getFamilies, {
    machineModelId: selections?.machine,
    toolSystemId: selections?.toolSystem,
    type: selections?.type,
  }), {}),
);

interface GetToolFamilyWithProductThunkArgs {
  toolFamilyId: string;
  machineModelId?: string;
}
export const getToolFamilyWithProduct = createAsyncThunk<
ToolFamily,
GetToolFamilyWithProductThunkArgs,
RejectValue
>(
  `GET ${APIRoutes.Tools.getFamily}`,
  async ({ toolFamilyId, machineModelId }, thunkAPI) => toolSelectorAPI(
    thunkAPI,
    withQuery(withParams(APIRoutes.Tools.getFamily, { toolFamilyId }), { machineModelId }),
    {},
  ),
);

export type AddToToolsPayload = {
  toolFamilyId: string;
  toolId: string;
};
const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    reset: () => initialState,
    addToTools: (state, action: PayloadAction<AddToToolsPayload>) => {
      const { toolFamilyId, toolId } = action.payload;
      const tool = state.toolFamilies.find(tf => tf.id === toolFamilyId)?.tools?.find(t => t?.product?.id === toolId);
      if (tool?.product) {
        state.items[tool.id] = tool.product;
      } else {
        throw new Error('Tool does not have a product!');
      }
    },
  },
  extraReducers:
  builder => {
    builder.addCase(getTool.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getTool.fulfilled, (state, action) => {
      const { payload: tool } = action;
      state.items[tool.id] = tool;
      state.isFetching = false;
    });
    builder.addCase(getTool.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(getToolFamilies.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getToolFamilies.fulfilled, (state, action) => {
      const { payload: toolFamilies } = action;
      state.toolFamilies = toolFamilies;
      state.isFetching = false;
    });
    builder.addCase(getToolFamilies.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(getToolFamilyWithProduct.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getToolFamilyWithProduct.fulfilled, (state, action) => {
      const { payload: toolFamily } = action;
      state.toolFamilies = state.toolFamilies.map(tf => tf.id === toolFamily.id ? toolFamily : tf);
      state.isFetching = false;
    });
    builder.addCase(getToolFamilyWithProduct.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const toolSelector = (toolId: string) => ({ tools }: { tools: ToolsSlice }) => tools.items[toolId];
export const isFetchingSelector = ({ tools }: { tools: ToolsSlice }) => tools.isFetching;
export const toolFamiliesSelector = ({ tools }: { tools: ToolsSlice }) => tools.toolFamilies;
export const toolFamilySelector = (selectedToolFamilyId?: string) => ({ tools }: { tools: ToolsSlice }) => tools.toolFamilies.find(tf => tf.id === selectedToolFamilyId);

export const {
  reset,
  addToTools,
} = toolsSlice.actions;

export default toolsSlice.reducer;
