import { useParams } from 'react-router-dom';
import { MarketType } from '@utils/formatting';
import FloorGrinding from './FloorGrinding';

type RouterParams = {
  market: string,
  material: string,
  finish: string,
  site: string,
  machine: string,
  toolSystem: string
};

/**
 * Wrapper component to abstract FloorGrinding component from React Router.
 * This component enables setting state with route paths:
 * /:material?/:finish?/:site?/:machine?/:toolSystem?
 */
const FloorGrindingRouteWrapper = () => {
  const { market, material, finish, site, machine, toolSystem } = useParams<RouterParams>();
  return (
    <FloorGrinding
      market={market as MarketType}
      material={material}
      finish={finish}
      site={site}
      machine={machine}
      toolSystem={toolSystem}
    />
  );
};

export default FloorGrindingRouteWrapper;
