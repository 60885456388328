import { useDispatch, useSelector } from 'react-redux';
import {
  EditRecipePayload,
  getRecipes as getRecipesAction,
  editRecipe as editRecipeAction,
  exportRecipe as exportRecipeAction,
  setMarket as setMarketAction,
  marketSelector,
  recipesSelector,
  finishFiltersSelector,
  materialFiltersSelector,
  machineFiltersSelector,
  toolSystemFiltersSelector,
} from '@store/recipesSlice';
import { Selections } from '@store/selectionSlice';
import { MarketType } from '@utils/formatting';

const useRecipes = () => {
  const dispatch = useDispatch();

  const market = useSelector(marketSelector);
  const setMarket = (marketType: MarketType) => dispatch(setMarketAction(marketType));

  const recipes = useSelector(recipesSelector);
  const getRecipes = (selections: Selections) => dispatch(getRecipesAction({ ...selections, includeRecipes: true }));

  const materialFilters = useSelector(materialFiltersSelector);
  const getMaterialFilters = (selections: Selections) => dispatch(getRecipesAction({ ...selections, filterMaterials: true }));

  const finishFilters = useSelector(finishFiltersSelector);
  const getFinishFilters = (selections: Selections) => dispatch(getRecipesAction({ ...selections, filterFinishes: true }));

  const machineFilters = useSelector(machineFiltersSelector);
  const getMachineFilters = (selections: Selections) => dispatch(getRecipesAction({ ...selections, filterMachines: true }));

  const toolSystemFilters = useSelector(toolSystemFiltersSelector);
  const getToolSystemFilters = (selections: Selections) => dispatch(getRecipesAction({ ...selections, filterToolSystems: true }));

  const editRecipe = (payload: EditRecipePayload) => dispatch(editRecipeAction(payload));
  const exportRecipe = () => dispatch(exportRecipeAction());

  return {
    market,
    setMarket,
    recipes,
    getRecipes,
    materialFilters,
    getMaterialFilters,
    finishFilters,
    getFinishFilters,
    machineFilters,
    getMachineFilters,
    toolSystemFilters,
    getToolSystemFilters,
    editRecipe,
    exportRecipe,
  };
};

export default useRecipes;
