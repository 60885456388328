import './Card.scss';

export interface CardDisplay {
  id: string;
  name: string;
  description?: string;
  imageURL?: string;
}
interface CardProps {
  article: CardDisplay,
  onClick: (article: CardDisplay) => void
}

const Card = ({ article, onClick }: CardProps) => {
  return (
    <article className="card">
      <button type="button" className="card-content" onClick={() => onClick(article)}>
        {article?.imageURL
          ? (<img src={article.imageURL} alt={article.name} />)
          : null}
        <h4>{article?.name}</h4>
        <p>{article?.description}</p>
      </button>
    </article>
  );
};

Card.defaultProps = {
  article: {
    id: 'ERROR',
    name: '',
    description: '',
    imageURL: undefined,
  },
};

export default Card;
