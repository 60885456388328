import { useDispatch, useSelector } from 'react-redux';
import {
  getToolConfigurations as getToolConfigurationsAction,
  toolConfigurationsSelector,
} from '@store/toolConfigurationSlice';

const useToolConfiguration = () => {
  const dispatch = useDispatch();

  const toolConfigurations = useSelector(toolConfigurationsSelector);
  const getToolConfigurations = (
    machineModelId: string,
    toolSelectionId: string,
  ) => dispatch(getToolConfigurationsAction({ machineModelId, toolSelectionId }));

  return {
    toolConfigurations,
    getToolConfigurations,
  };
};

export default useToolConfiguration;
