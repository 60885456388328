import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isFetchingSelector as isFetchingConfigSelector } from '@store/configSlice';
import { isFetchingSelector as isFetchingFinishesSelector } from '@store/finishesSlice';
import { isFetchingSelector as isFetchingMachinesSelector } from '@store/machinesSlice';
import { isFetchingSelector as isFetchingMaterialsSelector } from '@store/materialsSlice';
import { isFetchingSelector as isFetchingRecipesSelector } from '@store/recipesSlice';

const useSpinner = () => {

  const [showSpinner, setShowSpinner] = useState(false);
  const isFetchingConfig = useSelector(isFetchingConfigSelector);
  const isFetchingFinishes = useSelector(isFetchingFinishesSelector);
  const isFetchingMachines = useSelector(isFetchingMachinesSelector);
  const isFetchingMaterials = useSelector(isFetchingMaterialsSelector);
  const isFethingRecipes = useSelector(isFetchingRecipesSelector);

  useEffect(() => {
    const allFetchStates = [
      isFetchingConfig,
      isFetchingFinishes,
      isFetchingMachines,
      isFetchingMaterials,
      isFethingRecipes,
    ];

    const isAnyFetching = allFetchStates.some(b => !!b);
    if (isAnyFetching) {
      setShowSpinner(true);
    } else if (showSpinner) {
      setShowSpinner(false);
    }
  }, [
    isFetchingConfig,
    isFetchingFinishes,
    isFetchingMachines,
    isFetchingMaterials,
    isFethingRecipes,
  ]);

  return {
    showSpinner,
  };
};

export default useSpinner;
