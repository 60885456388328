import { useEffect, useState } from 'react';
import Card, { CardDisplay } from '@components/common/Card/Card';
import Grid from '@components/common/Grid/Grid';
import Modal from '@components/common/Modal/Modal';
import useMachines from '@hooks/useMachines';
import useSelection from '@hooks/useSelection';

import './MachineSelection.scss';
import Button from '@components/common/Button/Button';
import useRecipes from '@hooks/useRecipes';
import { Machine } from '@store/machinesSlice';

const MachinSelection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState<string>();
  const [selectedToolSystem, setSelectedToolSystem] = useState<string>();
  const { machines, getMachines, toolSystems, getToolSystems, resetToolSystems } = useMachines();
  const { material, finish, site, makeSelection } = useSelection();
  const { machineFilters, toolSystemFilters, getMachineFilters, getToolSystemFilters } = useRecipes();
  const [selectableMachines, setSelectableMachines] = useState<Machine[]>();

  useEffect(() => {
    getMachines();
    getMachineFilters({ material, finish, site });
  }, []);

  useEffect(() => {
    if (!!machines?.length && !!machineFilters?.length) {
      const filteredMachines = machines
        .filter(machine => machineFilters?.includes(machine.id));
      setSelectableMachines(filteredMachines);
    }
  }, [machines, machineFilters]);

  useEffect(() => {
    if (selectedMachine) {
      getToolSystems(selectedMachine);
    }
  }, [selectedMachine]);

  useEffect(() => {
    if (toolSystems?.length > 0) {
      const defaultToolSystem = toolSystems.find(t => t.default) || toolSystems[0];
      setSelectedToolSystem(defaultToolSystem?.id);
    }
  }, [toolSystems]);

  const onMachineClicked = (selection: CardDisplay) => {
    getToolSystemFilters({ material, finish, site, machine: selection.id });
    setSelectedMachine(selection.id);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedMachine(undefined);
  };

  const onToolSelection = () => {
    if (selectedMachine && selectedToolSystem) {
      makeSelection({ id: `${selectedMachine}&${selectedToolSystem}`, name: selectedToolSystem });
      resetToolSystems();
    }
  };

  useEffect(() => {
    onToolSelection();
  }, [selectedToolSystem]);

  return (
    <div className="machine-selection">
      {showModal
        && (
          <Modal>
            <div className="toolsystem-modal">
              <div className="header">
                <h2>Tool system preferences</h2>
                <Button type="ghost" onClick={onCloseModal}>X</Button>
              </div>
              <p>the selected machine is usually combined with tools from the Redi Tools system, but the EZ Tools System can also achieve your desired finish.</p>
              {toolSystems
                ?.filter(toolSystem => toolSystemFilters?.includes(toolSystem.id))
                .map(toolSystem => (
                  <label key={toolSystem.id}>
                    <input
                      type="radio"
                      value={toolSystem.id}
                      checked={selectedToolSystem ? toolSystem.id === selectedToolSystem : toolSystem.default}
                      className="form-check-input"
                      onChange={(e) => setSelectedToolSystem(e.target.value)}
                    />
                    {toolSystem.name}
                  </label>
                ))}
              <Button type="primary" block onClick={onToolSelection}>Continue</Button>
            </div>
          </Modal>
        )}

      <Grid id="machine-grid" columns={3}>
        {selectableMachines?.map(machine => (
          <Card
            key={machine.id}
            article={machine}
            onClick={onMachineClicked}
            />
        ))}
      </Grid>

    </div>
  );
};

export default MachinSelection;
