import { ReactElement } from 'react';
import './Modal.scss';

interface ModalProps {
  children: ReactElement[] | ReactElement;
}
const Modal = ({ children }: ModalProps) => {
  return (
    <div className="overlay">
      <div className="modal">
        {children}
      </div>
    </div>
  );
};

export default Modal;
