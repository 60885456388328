import { useEffect, useState } from 'react';
import { decode } from 'he';
import useFinishes from '@hooks/useFinishes';
import Card from '@components/common/Card/Card';
import Grid from '@components/common/Grid/Grid';
import useSelection from '@hooks/useSelection';
import useRecipes from '@hooks/useRecipes';
import { FinishType } from '@store/finishesSlice';

const FinishSelection = () => {
  const { finishes, getFinishes } = useFinishes();
  const { material, data, makeSelection } = useSelection();
  const { finishFilters, getFinishFilters } = useRecipes();
  const [selectableFinishes, setSelectablFinishes] = useState<FinishType[]>();

  useEffect(() => {
    getFinishes();
    getFinishFilters({ material });
  }, []);

  useEffect(() => {
    if (!!finishes?.length && !!finishFilters?.length) {
      const filteredFinishes = finishes
        .map(finishType => ({
          ...finishType,
          finishes: finishType.finishes.filter(finish => finishFilters?.includes(finish.id)),
        }))
        .filter(finishType => finishType.finishes.length > 0);
      setSelectablFinishes(filteredFinishes);
    }
  }, [finishes, finishFilters]);

  return (
    <div className="finish-selection">
      {selectableFinishes?.map(finishType => (
        <div key={finishType.name}>
          <h1>{decode(finishType.name)}</h1>
          <Grid id="finishes-grid" columns={2}>
            {finishType.finishes
              .map(finish => finish.imageURL?.includes('image-soon') ? ({ ...finish, imageURL: data.material?.imageURL }) : finish)
              .map(finish => (
                <Card
                  key={finish.id}
                  article={finish}
                  onClick={makeSelection}
                />
              ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default FinishSelection;
