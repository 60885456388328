/* eslint-disable no-param-reassign */
import { saveAs } from 'file-saver';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { APIEnabledSlice, APIRoutes, RejectValue, toolSelectorAPI, withQuery } from '@utils/tool-selector-api';
import { calculateQuantity, configurationName, extractGallons, extractLiters, formatProductNumber, marketFormats, MarketType } from '@utils/formatting';
import { Selections } from './selectionSlice';
import { ToolConfig } from './toolConfigurationSlice';

interface RecipesSlice extends APIEnabledSlice {
  items: Recipe[];
  filters: Filters;
  market: MarketType;
}

const initialState: RecipesSlice = {
  isFetching: false,
  market: MarketType.GLOBAL,
  items: [],
  filters: {},
};
export interface Recipe {
  id: string;
  marketId: string;
  materialId: string;
  finishId: string;
  machineModelId: string;
  steps: Array<RecipeStep>;
}
export interface Filters {
  markets?: string[];
  materials?: string[];
  finishes?: string[];
  machineModels?: string[];
  toolSystems?: string[];
}

interface Tool {
  dimrToolId: string;
  toolSelectionId: string;
  coverageArea?: number;
  toolQuantity?: number;
  toolConfig?: ToolConfig;
}

export interface RecipeStep {
  id: string;
  name: string;
  type: string;
  toolSystemId: string;
  tool: Tool;
  inactive?: boolean;
}

interface ExportBody {
  meta: {
    jobSiteSize: string;
    machineModel: string;
    materialName: string;
    finishName: string;
    showPrice: boolean;
    recipeName: string;
  };

  steps: Array<{
    configurationName: string;
    totalQuantity: number;
    type: string;
    machineName: string;
    typeEnumValue: string;
    gritSize?: string;
    chemicalsVolume?: string;

    productName: string;
    productNumber: string;
    productImageURL: string;

    toolProductNumber: string;
    toolName: string;
    toolImageURL: string;

    defaultCoverageArea?: number;
    customCoverageArea?: number;
    lifetime?: string;
    customLifetime?: number;
  }>;
}

const stateToExportBody = (state: RootState): ExportBody => {

  const { selection, materials, finishes, tools, recipes } = state;

  const { units, convertArea } = marketFormats[state.recipes.market];

  const material = materials.items.flatMap(mt => mt.materials).find(m => m.id === selection.material);
  const finish = finishes.items.flatMap(ft => ft.finishes).find(f => f.id === selection.finish);

  const materialName = `${material?.name}`;
  const finishName = `${finish?.name}`;

  const steps = recipes.items[0].steps.filter(step => !step?.inactive).map(s => {
    const stepTool = tools.items[s.tool.dimrToolId];

    const lifetime = convertArea(s.tool.toolConfig?.customToolLifeSpan || s.tool.toolConfig?.toolLifespan);
    const coverageArea = convertArea(s.tool?.coverageArea);
    const [, toolsPerPack] = stepTool?.name?.match(/(\d)PCS/) || ['', '1'];

    const typeSpecific = s.type === 'CHEMICAL'
      ? {
        totalQuantity: parseInt(calculateQuantity(selection.site, coverageArea), 10),
        chemicalsVolume: `${extractLiters(stepTool?.name) || extractGallons(stepTool?.name)} ${units.volume}` || 'N/A',
        lifetime: `${coverageArea} ${units.area}`,
      }
      : {
        totalQuantity: parseInt(calculateQuantity(selection.site, lifetime, s.tool.toolConfig?.toolQuantity, parseInt(toolsPerPack, 10)), 10),
        lifetime: `${lifetime} ${units.area}`,
      };

    return {
      configurationName: configurationName(s?.tool?.toolConfig?.toolQuantity, s?.tool?.toolConfig?.maxToolQuantity),

      type: `${s.type}`,
      typeEnumValue: `${s.type}`,
      machineName: `${state.selection.machine}/${state.selection.toolSystem}`,

      productName: `${stepTool.name}`,
      productNumber: `${formatProductNumber(stepTool.productNumber)}`,
      productImageURL: `${stepTool.imageURL}`,

      toolName: `${stepTool.name}`,
      toolProductNumber: `${formatProductNumber(stepTool.productNumber)}`,
      toolImageURL: `${stepTool.imageURL}`,

      ...typeSpecific,
    };
  });

  return {
    meta: {
      jobSiteSize: `${state.selection.site} ${units.area}`,
      machineModel: `${state.selection.machine} (${state.selection.toolSystem})`,
      recipeName: `${materialName} - ${finishName}`,
      materialName,
      finishName,
      showPrice: false,
    },
    steps,
  };
};
export interface RecipeQueryPayload {
  recipes: Recipe[];
  filters: Filters;
}
interface GetRecipesParams extends Selections {
  includeRecipes?: boolean;
  filterMaterials?: boolean;
  filterFinishes?: boolean;
  filterMachines?: boolean;
  filterToolSystems?: boolean;
}
export const getRecipes = createAsyncThunk<
RecipeQueryPayload,
GetRecipesParams,
RejectValue
>(
  `GET ${APIRoutes.Recipes.query}`,
  async (params, thunkAPI) => toolSelectorAPI(thunkAPI, withQuery(APIRoutes.Recipes.query, {
    includeRecipes: params?.includeRecipes,
    marketId: thunkAPI?.getState()?.recipes?.market,
    materialId: params?.material,
    finishId: params?.finish,
    machineModelId: params?.machine,
    toolSystemId: params?.toolSystem,
  }), {}),
);

export const exportRecipe = createAsyncThunk<
string,
void,
RejectValue
>(
  `POST ${APIRoutes.Recipes.export}`,
  async (_void, thunkAPI) => toolSelectorAPI(thunkAPI, APIRoutes.Recipes.export, {
    method: 'POST',
    body: JSON.stringify(stateToExportBody(thunkAPI.getState())),
    headers: {
      'Content-Type': 'application/pdf',
    },
  }),
);

export type EditRecipePayload = {
  recipeIndex: number;
  stepIndex: number;
  step: RecipeStep;
};
const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    reset: () => initialState,
    setMarket: (state, action: PayloadAction<MarketType>) => {
      state.market = action.payload;
    },
    editRecipe: (state, action: PayloadAction<EditRecipePayload>) => {
      const { recipeIndex, stepIndex, step } = action.payload;
      state.items[recipeIndex].steps[stepIndex] = step;
    },
  },
  extraReducers:
    builder => {
      builder.addCase(getRecipes.pending, (state) => {
        state.isFetching = true;
      });
      builder.addCase(getRecipes.fulfilled, (state, action) => {
        const { payload: { recipes, filters } } = action;
        const { filterMaterials, filterFinishes, filterMachines, filterToolSystems } = action?.meta?.arg || {};
        if (filterMaterials) {
          state.filters.materials = filters.materials;
        }
        if (filterFinishes) {
          state.filters.finishes = filters.finishes;
        }
        if (filterMachines) {
          state.filters.machineModels = filters.machineModels;
        }
        if (filterToolSystems) {
          state.filters.toolSystems = filters.toolSystems;
        }
        state.items = recipes;
        state.isFetching = false;
      });
      builder.addCase(getRecipes.rejected, (state) => {
        state.isFetching = false;
      });

      builder.addCase(exportRecipe.pending, (state) => {
        state.isFetching = true;
      });
      builder.addCase(exportRecipe.fulfilled, (state, action) => {
        const { payload } = action;
        saveAs(`data:application/pdf;base64,${payload}`, 'recipe.pdf');
        state.isFetching = false;
      });
      builder.addCase(exportRecipe.rejected, (state) => {
        state.isFetching = false;
      });
    },
});

export const marketSelector = ({ recipes }: { recipes: RecipesSlice }) => recipes.market;
export const recipesSelector = ({ recipes }: { recipes: RecipesSlice }) => recipes.items;
export const finishFiltersSelector = ({ recipes }: { recipes: RecipesSlice }) => recipes.filters?.finishes;
export const materialFiltersSelector = ({ recipes }: { recipes: RecipesSlice }) => recipes.filters?.materials;
export const machineFiltersSelector = ({ recipes }: { recipes: RecipesSlice }) => recipes.filters?.machineModels;
export const toolSystemFiltersSelector = ({ recipes }: { recipes: RecipesSlice }) => recipes.filters?.toolSystems;
export const isFetchingSelector = ({ recipes }: { recipes: RecipesSlice }) => recipes.isFetching;

export const {
  reset,
  setMarket,
  editRecipe,
} = recipesSlice.actions;

export default recipesSlice.reducer;
